/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { Flex } from "@theme-ui/components"
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout"
import Title from "@lekoarts/gatsby-theme-minimal-blog/src/components/title"
import Listing from "@lekoarts/gatsby-theme-minimal-blog/src/components/listing"
import List from "@lekoarts/gatsby-theme-minimal-blog/src/components/list"
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config"
import useSiteMetadata from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-site-metadata"
import replaceSlashes from "@lekoarts/gatsby-theme-minimal-blog/src/utils/replaceSlashes"
import { visuallyHidden } from "@lekoarts/gatsby-theme-minimal-blog/src/styles/utils"
// @ts-ignore
import Hero from "../texts/hero"
// @ts-ignore
import Touren from "../texts/touren"
// @ts-ignore
import Partner from "../texts/partner"
// @ts-ignore
import Bottom from "../texts/bottom"

import OMap from "../../../../components/Map"

type PostsProps = {
  posts: {
    slug: string
    title: string
    date: string
    excerpt: string
    description: string
    timeToRead?: number
    tags?: {
      name: string
      slug: string
    }[]
  }[]
  [key: string]: any
}    

const Homepage = ({ posts }: PostsProps) => {
  const { basePath, blogPath } = useMinimalBlogConfig()
  const { siteTitle } = useSiteMetadata()

  return (
    <Layout>
      <h1 sx={visuallyHidden}>{siteTitle}</h1>
      <section sx={{ mb: [5, 6, 6], p: { fontSize: [1, 2, 3], mt: 2 }, variant: `section_hero` }}>
        <Hero />
        <div style={{position: "relative", overflow: "hidden", width: "100%", paddingTop: "56.25%", marginBottom: "3em"}}>
          <iframe src="https://player.vimeo.com/video/529899697?autoplay=1" style={{position: "absolute", top: "0", left: "0", bottom: "0", right: "0", width: "100%", height: "100%"}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>
        <Bottom />
        {/* <OMap /> */}
      </section>
      <Title text="Neuigkeiten">
        <Link to={replaceSlashes(`/${basePath}/${blogPath}`)}>alle Nachrichten</Link>
      </Title>
      <Listing posts={posts} showTags={true} sx={{ mb: [5, 6, 6] }}/>
      {/* Liste von Projekten */}
      {/* <List sx={{ variant: `section_bottom` }}>
        <Bottom />
      </List> */}
      <Title text="Partner"/>
      <Partner/>
    </Layout>
  )
}

export default Homepage
