import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Besuchen Sie eine der Wiesentouren `}<a parentName="p" {...{
        "href": "/arboldswil-titterten"
      }}>{`Arboldswil-Titterten`}</a>{`
oder `}<a parentName="p" {...{
        "href": "/dittingen-laufen"
      }}>{`Dittingen-Laufen`}</a>{` oder buchen Sie die `}<a parentName="p" {...{
        "href": "https://eventfrog.ch/de/p/freizeit-ausfluege/wanderung/gefuehrte-wanderung-wiesentour-arboldswil-titterten-7162730665628091613.html"
      }}>{`geführte Tour am
16. Mai 2024`}</a>{` in Arboldswil (Treffpunkt: Arboldswil, Haltestelle Dorf 10.12 Uhr;
Verpflegung aus der Knabberkiste Hof Kastelen; Ende: 13.39 Titterten,
Haltestelle Gemeindehaus).`}</p>
    <p>{`Für
`}<a parentName="p" {...{
        "href": "https://www.pronatura-bl.ch/de/wiesenexkursionen-fuer-schulen"
      }}>{`Schulklassen`}</a>{`
bietet Pro Natura Baselland im Mai und im Juni Exkursionen zu einigen der
schönsten Wiesen der Umgebung an. Spielerisch entdecken die Schüler/-innen, was
eine artenreiche Wiese ausmacht, welches die wichtigsten Wiesenblumen sind und
welche Tiere sich in und um Wiesen aufhalten. Das Umweltbildungsangebot eignet
sich für alle Zyklen und ist auf den Lehrplan 21 ausgerichtet.`}</p>
    <p>{`Für individuelle Führungen ab 10 Personen vom 13. Mai bis 30. Juni 2024 nehmen
Sie Kontakt mit Pro Natura Baselland auf (`}<a parentName="p" {...{
        "href": "mailto:pronatura-bl@pronatura.ch"
      }}>{`pronatura-bl@pronatura.ch`}</a>{` oder 061 921
62 62).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      