import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Die Baselbieter Wiesentouren werden vom `}<a parentName="p" {...{
        "href": "https://www.bvbb.ch/"
      }}>{`Bauernverband beider Basel`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.baselland-tourismus.ch/"
      }}>{`Baselland Tourismus`}</a>{` und `}<a parentName="p" {...{
        "href": "https://www.pronatura-bl.ch/"
      }}>{`Pro Natura Baselland`}</a>{` mit Unterstützung des `}<a parentName="p" {...{
        "href": "https://www.baselland.ch/politik-und-behorden/direktionen/volkswirtschafts-und-gesundheitsdirektion/landw-zentrum-ebenrain/natur"
      }}>{`Ebenrain-Zentrums`}</a>{` durchgeführt.`}</p>
    <img src="bvbbbltpnbllze.png" width="100%" />
    <p>{`Wir danken für die grosszügige finanzielle Unterstützung durch den `}<a parentName="p" {...{
        "href": "https://www.baselland.ch/politik-und-behorden/direktionen/sicherheitsdirektion/swisslos-fonds"
      }}>{`Swisslos Fonds Baselland`}</a>{` und die `}<a parentName="p" {...{
        "href": "https://www.fondation-sur-la-croix.ch/de/"
      }}>{`Fondation Sur-la-Croix`}</a>{`.`}</p>
    <img src="blslfslc.png" width="100%" />
    <p>{`Die Exkursionen für Schulklassen werden finanziell unterstützt von der Stiftung zur Förderung der Pflanzenkenntnis, der Margarethe und Rudolf Gsell Stiftung und der Natur und Landschaftsschutzkommission Baselland.`}</p>
    <img src="sfpmrgsnlk.png" width="100%" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      